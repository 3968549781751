<template>
  <div class="grid grid-1 md:grid-cols-3 gap-10">
    <div class="shadow-lg flex flex-col rounded-bl-lg rounded-br-lg" v-for="item in items" :key="item.id">
      <img v-if="item.images.length" :src="item.images[0].path" class="w-full h-72 object-cover rounded-tl-lg rounded-tr-lg" alt="" />
      <div class="px-6 pt-10 pb-4 flex flex-col flex-grow border border-t-0 border-solid rounded-bl-lg rounded-br-lg border-gray-300 bg-white">
        <div class="flex flex-wrap">
          <div class="w-full md:w-2/3 pr-2">
            <h3 class="font-bold text-base">
              {{ item.name }}
            </h3>
          </div>
          <div class="flex-1">
            <div class="text-right">
              ab <span class="font-bold text-xl">{{ item.price_formatted }}</span
              ><br />
              pro Nacht
            </div>
          </div>
        </div>
        <div class="mb-4 flex flex-wrap flex-grow">
          <div class="flex items-center pr-3 text-primary" v-if="item.attributes['Auflastung']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z" clip-rule="evenodd" />
            </svg>
            &nbsp;<span>{{ item.attributes['Auflastung'][0] }}</span>
          </div>
          <div class="flex items-center text-primary" v-if="item.attributes['Anzahl Personen']">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="h-5 w-5" fill="currentColor">
              <path
                d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z"
              />
            </svg>

            &nbsp;<span>{{ item.attributes['Anzahl Personen'][0] }}</span>
          </div>
          <div class="mt-4">
            <div v-html="item.short_description"></div>
          </div>
        </div>
        <div>
          <!--
          <a :href="'/vermietung/' + item.slug" class="py-1.5 px-6 bg-white border border-solid border-primary text-primary text-sm hover:text-primary hover:no-underline"
            >Buchen</a
          >
          -->
          <hr class="border-gray-300" />
          <div class="flex items-center justify-end py-2">
            <!--
            <a :href="'/vermietung/' + item.slug" class="flex items-center text-gray-500 hover:text-gray-800 hover:no-underline transition-all">
              Details
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
            </a>
            -->
            <a
              :href="'/vermietung/' + item.slug + '/'"
              class="
                py-1.5 px-8 bg-white border border-solid border-primary text-primary text-sm
                hover:bg-primary hover:no-underline hover:text-white rounded-sm
                transition-all duration-300
              "
            >
              Details
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    token: {
      required: true,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    axios
      .get('https://hcloud-app1.neqst.de/rental/item/latest', {
        headers: {
          'x-auth-token': this.token,
        },
        params: {
          category: 'wohnwagen'
        }
      })
      .then((response) => {
        this.items = response.data.data
      })
  },
}
</script>
