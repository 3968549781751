import Vue from 'vue';
import Items from './Items.vue';
Vue.config.productionTip = false;

import './style.css'

/*
new Vue({
  render: h => h(Items, {
    props: {token: 'e2c66a3otEGGTdyv7F7JgQ8RMUiXEo4q'}
  }),
}).$mount('#neqst-rental-items');
*/

window.neQst = window.neQst || {}
window.neQst.initRentalItems = (params) => {
  return new Vue({
    render: h => h(Items, {
      props: {...params.props}
    })
  }).$mount(params.node || '#neqst-rental-items')
}
